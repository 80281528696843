import Image from 'next-export-optimize-images/image'

type Props = {
  title: string
  preTitle: string
  img: string
}

const Jumbotron = ({ title, preTitle, img }: Props) => {
  return (
    <section className="w-full mx-auto max-w-container grid xl:grid-cols-sm-left grid-cols-1 ">
      <div className="xl:pl-jumbotron-left xl:mt-24 row-start-2 xl:row-start-1">
        <h2 className="pb-5 leading-loose text-center xl:text-left text-md xl:text-xl xl:pl-1">
          {preTitle}
        </h2>
        <h1 className="px-12 mb-12 text-5xl leading-tight text-center xl:text-jumbotron xl:text-left xl:px-0 xl:mb-8">
          {title}
        </h1>
      </div>
      <div className="w-2/3 md:w-full max-w-[546px] mx-auto">
        <Image
          width={564}
          height={662}
          src={img}
          alt="jumbotron_image"
          priority
        />
      </div>
    </section>
  )
}

export default Jumbotron
